import './main.css';
import {ChangeEvent, MouseEvent, useState} from 'react';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import {postContactMessage} from './ContactApi';
import Loading from './loading.gif'

const Main = () => {
    const [contactFormData, setContactFormData] = useState({
        site: 'rglsoftware',
        name: '',
        email: '',
        message: '',
        captcha: '',
    });
    const [errors, setErrors] = useState({
        name: '',
        email: '',
        message: '',
        captcha: '',
        submit: ''
    });
    const [isMessageSent, setIsMessageSent] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)

    const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const inputId = event.target.id;
        const inputValue = event.target.value;

        setContactFormData(prevState => ({
            ...prevState,
            [inputId]: inputValue
        }));

        setErrors(prevErrors => ({
            ...prevErrors,
            [inputId]: ''
        }));
    };

    const onCaptchaVerify = (token: string) => {
        setContactFormData(prevState => ({
            ...prevState,
            captcha: token
        }));

        setErrors(prevErrors => ({
            ...prevErrors,
            captcha: ''
        }));
    };

    const validateForm = () => {
        const newErrors = {
            name: '',
            email: '',
            message: '',
            captcha: '',
            submit: ''
        };
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!contactFormData.name) newErrors.name = 'Name is required';
        if (!contactFormData.email) {
            newErrors.email = 'Email is required';
        } else if (!emailRegex.test(contactFormData.email)) {
            newErrors.email = 'Invalid email format';
        }
        if (!contactFormData.message) newErrors.message = 'Message is required';
        if (!contactFormData.captcha) newErrors.captcha = 'Captcha verification is required';

        setErrors(newErrors);
        return !Object.values(newErrors).some(error => error);
    };

    const handleSendMessage = (event: MouseEvent<HTMLInputElement>) => {
        event.preventDefault();
        if (validateForm()) {
            setIsSubmitting(true);
            postContactMessage(contactFormData)
                .then(() => {
                    setIsMessageSent(true);
                })
                .catch(() => {
                    setIsSubmitting(false);
                    setErrors(prevErrors => ({
                        ...prevErrors,
                        submit: 'Failed to send message. Please try again later.'
                    }));
                });
        }
    };

    return (
        <div>
            <header className="header">
                <h1>RGL Software</h1>
            </header>
            <main className="main">
                <p><strong>We</strong> are a new software development company that looks forward to help our clients to
                    boost their business through technology.</p>
                <p><strong>We</strong> focus on finding creative solutions to make business processes more efficient.
                </p>
                <img className="workImage"
                     src="https://media1.giphy.com/media/v1.Y2lkPTc5MGI3NjExanFncGwxYzV3bDE3dnkxZWF3aGFpejVzNGp6OXlibDhjcDdvd2cxZCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/HzPtbOKyBoBFsK4hyc/giphy.gif"/>

                <section className="contactSection">
                    {isMessageSent ? (
                        <div className="contactSection_sent">
                            <p>Your message has been sent!</p>
                            <p>We will contact you as soon as possible :)</p>
                            <p>Thank you for contacting us!</p>
                        </div>
                    ) : (
                        <div className="contactSection_fill">
                            <div className="contactSection__text">
                                <p>If you think we can help you, do not hesitate to contact us by filling out the form
                                    below.</p>
                                <p>We will contact you as soon as possible.</p>
                            </div>

                            <form className="contactForm">
                                <div className="contactFormInput">
                                    <label htmlFor={'name'}><strong>Name</strong></label>
                                    <input className={'contactFormInput__input'} id="name" type="text"
                                           placeholder="Enter your name" onChange={handleInputChange} maxLength={145}/>
                                    {errors.name && <span className="errorText">{errors.name}</span>}
                                </div>

                                <div className="contactFormInput">
                                    <label htmlFor={'email'}><strong>Email</strong></label>
                                    <input className={'contactFormInput__input'} id="email" type="email" name="email"
                                           placeholder="Email" onChange={handleInputChange} maxLength={145}/>
                                    {errors.email && <span className="errorText">{errors.email}</span>}
                                </div>

                                <div className="contactFormInput">
                                    <label htmlFor={'message'}><strong>Message</strong></label>
                                    <textarea className={'contactFormInput__input contactFormInput__area'} id="message"
                                              placeholder="Enter your message" onChange={handleInputChange}
                                              maxLength={990}/>
                                    {errors.message && <span className="errorText">{errors.message}</span>}
                                </div>

                                {isSubmitting ? (
                                    <img className="contactForm__loading" src={Loading} alt="Sending message..."/>
                                ) : (
                                    <HCaptcha
                                        sitekey="08e11f6b-f6db-4e50-a116-e13cd73fd6ca"
                                        onVerify={onCaptchaVerify}
                                    />
                                )}
                                {errors.captcha && <span className="errorText">{errors.captcha}</span>}
                                {errors.submit && <span className="errorText">{errors.submit}</span>}

                                <input className="contactForm-submit" type="submit" value="Send message"
                                       onClick={handleSendMessage} disabled={isSubmitting}/>
                            </form>
                        </div>
                    )}
                </section>
            </main>
            <footer className="footer">
                <h3>2024</h3>
            </footer>
        </div>
    );
}

export default Main;
